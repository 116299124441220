import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";

import Header from "../components/Header";
import Footer from "../components/Footer";

const MainContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WidthLimiter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        genre
        title
        date(formatString: "MMMM, YYYY")
        role
        tools
        linkto
        description
      }
      html
    }
  }
`;

const WorkDetail = styled.div`
  padding: 0 32px;
  margin-top: 10vh;
  a {
    color: white;
    text-decoration: none;
  }
  span {
    color: rgba(255, 255, 255, 0.8);
  }
  .detail__overview {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    column-gap: 32px;
    .overview_right {
      h3 {
        margin-top: 16px;
        margin-bottom: 0;
      }
    }
  }
  .detail__detail {
    margin-top: 40px;
    padding: 40px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    text-align: left;
    line-height: 2;
    font-family: "Open Sans", sans-serif;
    span {
      max-width: 100% !important;
    }
    img {
      width: 100% !important;
    }
  }
  .genre {
    font-size: 1rem;
    font-weight: 700;
    padding: 2px 1rem;
    border: 2px solid white;
  }
`;

const Work = (props) => {
  const data = props.data.markdownRemark;

  return (
    <>
      <MainContents>
        <WidthLimiter>
          <Header pageTitle={data.frontmatter.title}></Header>
          <WorkDetail>
            <div className="detail__overview">
              <div className="overview__left">
                <span className="genre">{data.frontmatter.genre}</span>
                <h1>{data.frontmatter.title}</h1>
                <p>
                  {data.frontmatter.description
                    .split("\n")
                    .map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                </p>
              </div>
              <div className="overview_right">
                <h3>{data.frontmatter.date}</h3>
                <h3>Role</h3>
                <span>{data.frontmatter.role}</span>
                <h3>Tools</h3>
                <span>{data.frontmatter.tools}</span>
                {data.frontmatter.linkto === "" ? null : (
                  <h3>
                    <Link target="_blank" to={data.frontmatter.linkto}>
                      Link to 👉
                    </Link>
                  </h3>
                )}
              </div>
            </div>
            <div
              className="detail__detail"
              dangerouslySetInnerHTML={{ __html: data.html }}
            ></div>
          </WorkDetail>
          <Footer></Footer>
        </WidthLimiter>
      </MainContents>
    </>
  );
};

export default Work;
